import MainPaperComponent from '../main-card/main-card.components';
import Box from '@mui/material/Box';

import './main.container.scss';

const MainComponent = () => {
    return (
        <Box className="main-container">
            <MainPaperComponent />
        </Box>
    )
}

export default MainComponent;
