import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';

import './main-card.component.scss';

import Photoshop from '../../static/images/photoshop.svg';
import Illustrator from '../../static/images/illustrator.svg';
import Indesign from '../../static/images/indesign.svg';

const MainPaperComponent = () => {
    return (
        <div className="cube-container">
            <div className="cube">
                <div className="cube-content">
                    <h1 className="name-title">Hey, I'm Nitika!</h1>

                    <div className="designation-container">
                        <div className="designation-wrapper">
                            <div className="designation">Communication Designer</div>
                            <div className="designation">Illustrator</div>
                            <div className="designation">Animator</div>
                        </div>
                    </div>

                    <div className="about-container">
                        <div className="left-container">
                            New Delhi based, I'm a designer with a passion
                            for illustration, typography & animations.
                        </div>

                        <div className="right-container">
                            Currently I'm studying communication design at <a href="https://www.iiad.edu.in/" rel="noreferrer" target="_blank">IIAD</a>.
                            Previously I've worked with companies to make their brand more visible.
                        </div>
                    </div>

                    <div className="technologies">
                        <div className="title">
                            Some technologies that I have recently used:
                        </div>

                        <div className="technologies-icons">
                            <img src={Photoshop} className="icon" alt="Photoshop" />
                            <img src={Illustrator} className="icon" alt="Illustrator" />
                            <img src={Indesign} className="icon" alt="Indesign" />
                        </div>
                    </div>

                    <div className="contact-container">
                        <div className="contact">
                            <a href="#" className="btn">
                                Download CV
                            </a>

                            <a className="email" href="mailto:nitika.sardana19@iiad.edu.in" rel="noreferrer" target="_blank">
                                <EmailIcon sx={{ fontSize: 40 }} />
                            </a>
                            <a className="linkedin" href="https://www.linkedin.com/in/nitika-sardana-80494b18b" rel="noreferrer" target="_blank">
                                <LinkedInIcon sx={{ fontSize: 40 }} />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="blur"></div>
        </div>
    )
}

export default MainPaperComponent;
