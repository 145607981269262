import MainComponent from './components/main-container/main.container';

import './App.scss';

function App() {
  return (
    <div className="App">
      <MainComponent />
    </div>
  );
}

export default App;
